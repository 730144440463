import { useEffect } from 'react';
import { Footer, Navbar } from '../components';

function Terms() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Navbar />
      <div className="min-h-screen bg-product font-sora justify-start items-center text-[16px] max-md:text-[14px] min-w-full text-policy">
        <div className="relative flex justify-center items-center w-full py-20 mt-20">
          <div className="text-7xl max-md:text-4xl max-sm:text-4xl px-20 max-md:px-12 font-bold text-black text-center font-inherit relative">
            <img
              src="https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1724583362/pixa-pie/wzp2y17kffzrnk1vrvqt.png"
              alt="arrow-left"
              className="absolute left-0 top-0 w-[64.748px] max-md:w-[40px] h-[24.521px] max-md:h-[20px] rotate-[-155.302deg]"
            />
            <p>Terms and Conditions</p>
            <span className="absolute -bottom-5 max-md:-bottom-3 h-[12px] max-md:h-[8px] bg-product-subs-heading-underline-pattern  bg-no-repeat w-[85%] max-xl:w-[60%] left-16 max-xl:left-[18vw] bg-100"></span>
            <img
              src="https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1724583362/pixa-pie/wzp2y17kffzrnk1vrvqt.png"
              alt="arrow-right"
              className="absolute right-0 top-0 w-[64.748px] max-md:w-[40px] h-[24.521px] max-md:h-[20px] rotate-[-8.571deg]"
            />
          </div>
        </div>
        <div className="lg:leading-10 md:leading-8 max-md:leading-6 text-policy">
          <span className="text-[20px] max-lg:text-[14px] text-policy opacity-80 lg:px-[4.2rem] md:px-[3.5rem] max-md:px-4">
            <b>Last updated: August 23, 2024</b>
          </span>
          <br />
          <p className="text-inherit text-policy opacity-80 lg:px-[4.2rem] md:px-[3.5rem] max-md:px-4 mb-4">
            Please read these terms and conditions carefully before using Our
            Service.
          </p>
          <ul className="list-decimal lg:px-24 md:px-20 max-md:px-8">
            <li className="lg:text-2xl md:text-xl max-md:text-[16px] font-bold">
              Definitions
            </li>
            <p className="mt-2 mb-4">
              <ul className="list-disc pl-5">
                <li>
                  "Company," "we," "us," or "our" refers to Pixaverse Studios
                  Private Limited, based in Bengaluru, Karnataka, India.
                </li>
                <li>
                  "Service" means our website, accessible at
                  https://www.heypixa.ai
                </li>
                <li>
                  {' '}
                  "You" means the individual or entity using our Service.
                </li>
                <li> "Terms" refers to these Terms of Service.</li>
              </ul>
            </p>
            <li className="lg:text-2xl md:text-xl max-md:text-[16px] font-bold">
              Acceptance of Terms
            </li>
            <p className="mt-2 mb-4">
              By using our Service, you agree to these Terms. If you disagree
              with any part, please do not use our Service.
            </p>
            <li className="lg:text-2xl md:text-xl max-md:text-[16px] font-bold">
              Privacy Policy
            </li>
            <p className="mt-2 mb-4">
              Our Privacy Policy, available on our website, explains how we
              handle your personal information. Please review it before using
              our Service.
            </p>
            <li className="lg:text-2xl md:text-xl max-md:text-[16px] font-bold">
              Third-Party Links
            </li>
            <p className="mt-2 mb-4">
              Our Service may contain links to external websites. We are not
              responsible for the content or practices of these sites. Use them
              at your own risk.
            </p>
            <li className="lg:text-2xl md:text-xl max-md:text-[16px] font-bold">
              Termination
            </li>
            <p className="mt-2 mb-4">
              We may terminate or suspend your access to our Service
              immediately, without prior notice, for any reason, including if
              you violate these Terms.
            </p>
            <li className="lg:text-2xl md:text-xl max-md:text-[16px] font-bold">
              Limitation of Liability
            </li>
            <p className="mt-2 mb-4">
              Our liability for any claims related to the Service is limited to
              the amount you've paid for the Service or $100 USD, whichever is
              greater.
            </p>

            <li className="lg:text-2xl md:text-xl max-md:text-[16px] font-bold">
              Disclaimer of Warranties
            </li>
            <p className="mt-2 mb-4">
              We provide our Service "as is" without any warranties. We do not
              guarantee that the Service will be uninterrupted, error-free, or
              meet your specific requirements.
            </p>

            <li className="lg:text-2xl md:text-xl max-md:text-[16px] font-bold">
              Governing Law
            </li>
            <p className="mt-2 mb-4">
              These Terms are governed by the laws of Karnataka, India.
            </p>

            <li className="lg:text-2xl md:text-xl max-md:text-[16px] font-bold">
              Dispute Resolution
            </li>
            <p className="mt-2 mb-4">
              If you have concerns, please contact us first to try to resolve
              the issue informally.
            </p>
            <li className="lg:text-2xl md:text-xl max-md:text-[16px] font-bold">
              Changes to Terms
            </li>
            <p className="mt-2 mb-4">
              We may update these Terms at any time. For significant changes,
              we'll try to provide at least 30 days' notice. Your continued use
              of the Service after changes indicates your acceptance of the new
              Terms.
            </p>
            <li className="lg:text-2xl md:text-xl max-md:text-[16px] font-bold">
              Contact Us
            </li>
            <p className="mt-2 mb-4">
              For any questions about these Terms, please contact us at
              sparsh@heypixa.ai
            </p>
          </ul>
          <br />
          <br />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Terms;
