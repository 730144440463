import { useEffect } from 'react';
import { Footer, Navbar } from '../components';

function RefundPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Navbar />
      <div className="min-h-screen bg-product font-sora justify-start items-center text-[16px] max-md:text-[14px] min-w-full text-policy">
        <div className="relative flex justify-center items-center w-full py-20 mt-20">
          <div className="text-7xl max-md:text-4xl max-sm:text-4xl px-20 max-md:px-12 font-bold text-black text-center font-inherit relative">
            <img
              src="https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1724583362/pixa-pie/wzp2y17kffzrnk1vrvqt.png"
              alt="arrow-left"
              className="absolute left-0 top-0 w-[64.748px] max-md:w-[40px] h-[24.521px] max-md:h-[20px] rotate-[-155.302deg]"
            />
            <p>Refund Policy</p>
            <span className="absolute -bottom-5 max-md:-bottom-3 h-[12px] max-md:h-[8px] bg-product-subs-heading-underline-pattern  bg-no-repeat w-[80%] max-md:w-[70%] left-16 max-md:left-12 bg-100"></span>
            <img
              src="https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1724583362/pixa-pie/wzp2y17kffzrnk1vrvqt.png"
              alt="arrow-right"
              className="absolute right-0 top-0 w-[64.748px] max-md:w-[40px] h-[24.521px] max-md:h-[20px] rotate-[-8.571deg]"
            />
          </div>
        </div>
        <div className="lg:leading-10 md:leading-8 max-md:leading-6 text-policy">
          <span className="text-[20px] max-lg:text-[14px] text-policy opacity-80 lg:px-[4.2rem] md:px-[3.5rem] max-md:px-4">
            <b>Last updated: August 23, 2024</b>
          </span>
          <br />
          <br />

          <ul className="list-decimal lg:px-24 md:px-20 max-md:px-8">
            <li className="lg:text-2xl md:text-xl max-md:text-[16px] font-bold">
              Return Eligibility
            </li>
            <p className="mt-2">
              We accept returns on items that are:
              <ul className="list-disc pl-5">
                <li>Unused and in original condition</li>
                <li>Still have tags attached</li>
                <li>In original packaging</li>
              </ul>
            </p>
            <p className="mt-4 mb-4">
              You must include your digital receipt or proof of purchase with
              the return.
            </p>
            <li className="lg:text-2xl md:text-xl max-md:text-[16px] font-bold">
              Starting a Return
            </li>
            <p className="mt-2 mb-4">
              To initiate a return, please contact us at sparsh@heypixa.ai for
              instructions. Customers are responsible for return shipping costs.
            </p>
            <li className="lg:text-2xl md:text-xl max-md:text-[16px] font-bold">
              Condition Requirements
            </li>
            <p className="mt-2 mb-4">
              While you may open packaging to inspect items, returns must be in
              pristine, unused condition. We can only accept items in their
              original state.
            </p>
            <li className="lg:text-2xl md:text-xl max-md:text-[16px] font-bold">
              Damaged or Incorrect Orders
            </li>
            <p className="mt-2 mb-4">
              Inspect your order upon arrival and let us know right away about
              any defects, damage, or incorrect items so we can resolve the
              issue.
            </p>
            <li className="lg:text-2xl md:text-xl max-md:text-[16px] font-bold">
              Exchanges
            </li>
            <p className="mt-2 mb-4">
              For exchanges, simply return the original item and place a new
              order for the desired product. Customers cover return shipping
              costs.
            </p>
            <li className="lg:text-2xl md:text-xl max-md:text-[16px] font-bold">
              International Returns
            </li>
            <p className="mt-2 mb-4">
              International customers should contact us before returning items.
              Be aware that additional fees like customs charges may apply and
              are the customer's responsibility.
            </p>

            <li className="lg:text-2xl md:text-xl max-md:text-[16px] font-bold">
              Refund Processing
            </li>
            <p className="mt-2 mb-4">
              We'll notify you once we've inspected your return. Approved
              refunds will be issued to the original payment method within 10
              business days. Allow extra time for your bank to process the
              refund. Contact us if you haven't received it after 15 business
              days.
            </p>

            <li className="lg:text-2xl md:text-xl max-md:text-[16px] font-bold">
              Restocking Fees
            </li>
            <p className="mt-2 mb-4">
              A restocking fee may be charged to cover return processing costs.
              We aim for transparency about any fees affecting refunds.
            </p>

            <li className="lg:text-2xl md:text-xl max-md:text-[16px] font-bold">
              Shipping & Handling
            </li>
            <p className="mt-2 mb-4">
              Shipping and handling fees are typically non-refundable,
              especially for international returns that may incur extra charges.
              This helps us maintain fair and efficient processes.
            </p>
            <li className="lg:text-2xl md:text-xl max-md:text-[16px] font-bold">
              Got questions?
            </li>
            <p className="mt-2 mb-4">
              For any questions about our return and refund policies, please
              reach out to sparsh@heypixa.ai.
            </p>
          </ul>
          <br />
          <br />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default RefundPolicy;
