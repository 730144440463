import { useEffect } from 'react';
import { Footer, Navbar } from '../components';

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Navbar />
      <div className="min-h-screen bg-product font-sora justify-start items-center text-[16px] max-md:text-[14px] min-w-full text-policy">
        <div className="relative flex justify-center items-center w-full py-20 mt-20">
          <div className="text-7xl max-md:text-4xl max-sm:text-4xl px-20 max-md:px-12 font-bold text-black text-center font-inherit relative">
            <img
              src="https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1724583362/pixa-pie/wzp2y17kffzrnk1vrvqt.png"
              alt="arrow-left"
              className="absolute left-0 top-0 w-[64.748px] max-md:w-[40px] h-[24.521px] max-md:h-[20px] rotate-[-155.302deg]"
            />
            <p>Privacy Policy</p>
            <span className="absolute -bottom-5 max-md:-bottom-3 h-[12px] max-md:h-[8px] bg-product-subs-heading-underline-pattern  bg-no-repeat w-[80%] max-md:w-[70%] left-16 max-md:left-12 bg-100"></span>
            <img
              src="https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1724583362/pixa-pie/wzp2y17kffzrnk1vrvqt.png"
              alt="arrow-right"
              className="absolute right-0 top-0 w-[64.748px] max-md:w-[40px] h-[24.521px] max-md:h-[20px] rotate-[-8.571deg]"
            />
          </div>
        </div>

        <div className="lg:leading-10 md:leading-8 max-md:leading-6 text-policy">
          <span className="text-[20px] max-lg:text-[14px] text-policy opacity-80 lg:px-[4.2rem] md:px-[3.5rem] max-md:px-4">
            <b>Last updated: August 23, 2024</b>
          </span>
          <br />
          <br />
          <ul className="list-decimal lg:px-24 md:px-20 max-md:px-8">
            <li className="lg:text-2xl md:text-xl max-md:text-[16px] font-bold">
              Introduction
            </li>
            <p className="mt-2 mb-6">
              Pixaverse Studios Private Limited ("we," "us," or "our") respects
              your privacy. This policy describes how we collect, use, and
              protect information when you use our Pixa AI app, hardware
              devices, and website (heypixa.ai).
            </p>
            <li className="lg:text-2xl md:text-xl max-md:text-[16px] font-bold">
              Information We Collect
            </li>
            <p className="mt-2">
              We collect information you provide directly, such as:
              <ul className="list-disc pl-10">
                <li>Account details (name, email, password)</li>
                <li>Child information (name, birth date)</li>
                <li> Voice interactions with our devices</li>
              </ul>
            </p>
            <p className="mt-4 mb-4">
              We also automatically collect:
              <ul className="list-disc pl-10">
                <li>Usage data</li>
                <li>Device information</li>
                <li>Location data (to support device functionality)</li>
              </ul>
            </p>
            <li className="lg:text-2xl md:text-xl max-md:text-[16px] font-bold">
              How We Use Your Information
            </li>
            <p className="mt-2 mb-4">
              We collect information you provide directly, such as:
              <ul className="list-disc pl-10">
                <li>Provide and improve our services</li>
                <li>Personalize your experience</li>
                <li> Communicate with you</li>
                <li>Ensure compliance with laws</li>
              </ul>
            </p>
            <li className="lg:text-2xl md:text-xl max-md:text-[16px] font-bold">
              Children's Privacy
            </li>
            <p className="mt-2 mb-4">
              We comply with the Children's Online Privacy Protection Act
              (COPPA). We collect minimal information about children under 13,
              only as necessary for using our devices. Parents can review,
              change, or delete their child's information at any time.
            </p>
            <li className="lg:text-2xl md:text-xl max-md:text-[16px] font-bold">
              Information Sharing
            </li>
            <p className="mt-2 mb-4">
              We don't sell your personal information. We may share information:
              <ul className="list-disc pl-10">
                <li>With service providers who help us operate our business</li>
                <li>If required by law</li>
                <li>In the event of a business transfer</li>
              </ul>
            </p>
            <li className="lg:text-2xl md:text-xl max-md:text-[16px] font-bold">
              Your Choices
            </li>
            <p className="mt-2 mb-4">
              You can:
              <ul className="list-disc pl-10">
                <li>Opt out of promotional communications</li>
                <li>
                  Adjust your device and browser settings for cookies and
                  location data
                </li>
                <li>Review and update your personal information</li>
              </ul>
            </p>

            <li className="lg:text-2xl md:text-xl max-md:text-[16px] font-bold">
              Data Security
            </li>
            <p className="mt-2 mb-4">
              We use industry-standard measures to protect your information.
              However, no method of transmission over the internet is 100%
              secure.
            </p>

            <li className="lg:text-2xl md:text-xl max-md:text-[16px] font-bold">
              Changes to This Policy
            </li>
            <p className="mt-2 mb-4">
              We may update this policy periodically. We'll notify you of
              significant changes.
            </p>

            <li className="lg:text-2xl md:text-xl max-md:text-[16px] font-bold">
              Contact Us
            </li>
            <p className="mt-2 mb-4">
              For questions about this policy, contact us at sparsh@heypixa.ai
            </p>
          </ul>

          <br />
          <br />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
